html, body, div{
    margin: 0;
    height: 100%;
}

.hero-image {
    background-image: url("home\ banner.JPG");
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center center;
    position:relative;
    overflow:hidden;
}

.hero-text {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%);
    color: white;
}